import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import RecipeSchema from "./RecipeSchema"

const SiteMetadata = ({ title, description, image, recipe }) => {
  const {
    site: {
      siteMetadata: { locale, siteTitle },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          locale
          siteTitle: title
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        defer={false}
        defaultTitle={siteTitle}
        title={title}
        titleTemplate={`%s | ${siteTitle}`}
      >
        <html lang={locale} />
        <meta charset="utf-8"/>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="image" content={image}/>
        <meta name="description" content={description} />

        <meta itemprop="name" content={title}/>
        <meta itemprop="description" content={description}/>
        <meta itemprop="image" content={image}/>

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={title}/>
        <meta name="twitter:image:src" content={image}/>

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:locale" content={locale} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:image" content={image} />
      </Helmet>
    {recipe && <RecipeSchema recipe={recipe} image={image}></RecipeSchema>}
    </>
  )
}

SiteMetadata.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
}

SiteMetadata.defaultProps = {
  image: "/social.png",
}

export default SiteMetadata
