import React from "react"
import LdJson from "./LdJson"
import cleanDeep from "clean-deep"

export default function RecipeSchema({recipe, image}) {
  // "datePublished": recipe.date['en-US'],
  let schema = {
    "@context": "https://schema.org",
    "@type": "Recipe",
    "image": image,
    "recipeYield": recipe.yield?.['en-US'],
    "description": recipe.description?.['en-US'],
    "keywords": recipe.keyWords?.['en-US'],
    "recipeCategory": recipe.category?.['en-US'],
    "recipeCuisine": recipe.cuisine?.['en-US'],
    "prepTime": recipe.prepTime && `PT${recipe.prepTime?.['en-US']}M`,
    "cookTime": recipe.cookTime && `PT${recipe.cookTime?.['en-US']}M`,
    "author": "Vegan Blender",
    "recipeIngredient": recipe.ingredients?.['en-US'],
    "name": recipe.name?.['en-US'],
    "suitableForDiet": "https://schema.org/VeganDiet",
    "recipeInstructions": recipe.steps?.['en-US'].map(step=> ({
        "@type": "HowToStep",
        "text": step
      })
    ),
  }

  return (
    <LdJson>
      {cleanDeep(schema)}
    </LdJson>
  )
}